@import "mixins"
.infoForm
    margin-bottom: 6px
    background: var(--acount_detailsSectionBg)
    border-radius: 3px
    overflow: hidden
    position: relative

.header
    @include flex
    justify-content: space-between
    height: 44px
    border-radius: 3px 3px 0 3px
    padding: 0 12px
    text-transform: uppercase
    background: var(--acount_detailsSectionHeaderBg)
    box-shadow: inset 1px 0 0 rgb(var(--particularColor))
    color: #fff
    position: relative
    z-index: 1

.content
    padding: 16px 12px 0
    position: relative

    div:has(:global(.field-error))
        input
            box-shadow: inset 0 0 0 1px rgb(var(--particularColor2))

    > div:not(.btnContainer):not(.userInfoNotification)
        padding-bottom: 10px
        label,
        :global(.intl-tel-input-label)
            text-transform: capitalize
            margin-bottom: 6px
            font-size: 12px
            display: block

        &:after
            top: 36px

.btnContainer
    @include flex

    button
        width: 40px
        min-width: 40px
        height: 32px
        flex: 1

        &:first-of-type
            position: relative

        :global(.loader) span
            font-size: 12px

        &:nth-child(2)
            margin-left: 14px
        .saveIcon span
            @include flex
            svg
                width: 14px
                height: 10px
                path
                    fill: var(--button_color)
        .cancelIcon span
            @include flex
            svg
                path
                    fill: var(--txt_color)

.userInfoNotification
    margin-bottom: 8px
    padding-bottom: 0

@media screen and (max-width: 320px) and (orientation: portrait)
    .btnContainer button
        width: 34px
        min-width: 34px
        height: 30px
        flex: 1

        &:nth-child(2)
            margin-left: 6px
