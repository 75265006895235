@import "mixins"
.header
  @include accountStickyHeader

.back
  width: 10px
  height: 5px
  min-width: 10px
  background: var(--icon-arrow) no-repeat
  transform: rotate(90deg)
  transform-origin: 45%
  margin-top: -2px

.becomeReferralForm
  padding-bottom: calc(var(--menuHeight) + 20px)

.referralTxt
  line-height: 20px
  margin: 10px 0 20px 0
  padding: 0 4px

.referralName
  border-top: 1px dotted var(--acount_headerBorder)
  padding-top: 10px
  label
    margin-bottom: 6px
    font-size: 12px
    text-transform: unset
  > span
    opacity: 0.8
    margin: 10px 0
    font-size: 11px
    display: block
  .error
    color: #fe383d
.inputError input
  border: 1px solid #fe383d

.checkbox
  @include flex
  border-top: 1px dotted var(--acount_headerBorder)
  padding-top: 20px
  line-height: 14px

.becomeReferralBtn
  text-transform: none
  width: 100%
  margin-top: 20px

.notVerified fieldset > *:not(.verifyMessageTooltip)
  opacity: 0.4
  pointer-events: none