@import "mixins"
.inputStyle
  border: 0
  background: var(--field_bg)
  border-radius: var(--base_borderRadius)
  padding: 0 10px
  outline: none
  font-size: 14px
  color: #fff
  width: 100%
  height: 36px
  &::placeholder
    color: rgba(var(--primaryColor), 0.7)
    text-transform: uppercase
    font-size: 12px

.label
  text-transform: uppercase
  font-size: 11px
  @include flex

.error
  color: rgb(var(--particularColor2))
  font-size: 13px
  line-height: 14px
  position: relative
  top: 5px
  display: block
  overflow: hidden
  height: auto
  max-height: 0
  opacity: 0
  animation: maxHeight .4s
  animation-fill-mode: forwards
  @keyframes maxHeight
    50%
      opacity: 1
    100%
      max-height: 100px
      opacity: 1

.fieldError
  position: relative
  input
    border-color: rgb(var(--particularColor2))
