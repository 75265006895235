@import "mixins"
.infoForm
    background: var(--account_detailsCompactInfoFormBg)
    border-radius: 8px
    margin-bottom: 6px
    overflow: hidden
    position: relative
    padding-bottom: 0!important

    &.opened .header:after
        transform: rotate(0)

.header
    padding: 6px 10px
    @include flex
    min-height: 52px
    font-size: 16px
    color: #fff
    background: var(--account_detailsCompactInfoFormBg)
    position: relative
    z-index: 3

    &::after
        content: ""
        @include backArrowBtn
        transform: rotate(-90deg)
        transition: all .3s
        margin-left: 12px

.blockName
    text-transform: capitalize
    margin-right: auto
    flex: 1
    @include flex
    overflow: hidden

    > :global(.svg-icon):first-of-type
        margin-right: 10px
        svg
            width: 32px
            height: 32px

        + span
            @include ellipsis

.content
    background: var(--account_detailsCompactInfoFormContentBg)
    border-top: 2px solid var(--body_bg)
    border-bottom: 2px solid var(--body_bg)
    padding: 10px 10px 0

    > div
        margin-bottom: 10px
        label
            text-transform: capitalize
            font-size: 14px
            line-height: 16px
            margin-bottom: 6px
            display: block
            padding: 0!important

        &:has(:global(.viewPass)) + p
            background: var(--account_detailsCompactInfoFormBg)
            font-size: 14px
            line-height: 18px
            color: rgb(var(--primaryColor))
            padding: 6px 8px
            border-radius: 4px
            margin: 10px 0

.btnContainer
    @include flex
    height: 44px

    > button
        flex: 1
        background: transparent!important
        font-size: 14px
        border-radius: 0
        position: relative
        height: 100%
        &:disabled
            opacity: .3
        > span
            position: relative
            z-index: 1
            @include flex
        :global(.svg-icon)
            margin-right: 10px
            svg
                height: 12px
                width: auto
        &:nth-child(1)
            color: #fff
        &:nth-child(2)
            color: rgb(var(--thirdaryColor))
            svg
                path
                    fill: rgb(var(--thirdaryColor))

        &:active:before, &:hover:before
            content: ""
            height: 32px
            background: var(--account_detailsCompactInfoFormButtonActiveBg)
            border-radius: 30px
            position: absolute
            left: 50%
            top: 50%
            transform: translate(-50%, -50%)
            width: 0
            animation: buttonHoverEffect .3s
            @keyframes buttonHoverEffect
                0%
                    width: 0px
                80%
                    opacity: 1
                    width: 80%
                100%
                    width: 80%
                    opacity: 0

.userInfoNotification
    margin-bottom: 8px
    padding-bottom: 0
