@import "mixins"
.changePassword
  padding: 0 0 12px

  div:has(:global(.field-error))
    input
      box-shadow: inset 0 0 0 1px rgb(var(--particularColor2))

  > div
    input
      padding-right: 36px

    label
      padding-top: 12px
      + div
        position: relative

    :global(.viewPass)
      max-height: 36px

  p
    color: var(--account_detailsTxtColor)
    padding: 12px 0 0
    line-height: 16px
    @include flex
    :global(.svg-icon)
      margin-right: 10px

.btnContainer
  @include flex
  position: absolute
  right: 10px
  top: -38px
  z-index: 1

  button
    width: 40px
    min-width: 40px
    height: 32px
    flex: 1

    &:first-of-type
      margin-right: 14px

  .saveIcon span
    @include flex
    svg
      width: 14px
      height: 10px
      path
        fill: var(--button_color)

  .resetIcon span
    @include flex
    svg
      width: 14px
      height: 14px
      path
        fill: var(--txt_color)

.passwordChanged
  margin-top: 12px
  display: flex
  color: #42b147
  @include bold
  background: var(--body_bg)
  border-radius: 4px
  min-height: 80px
  flex-direction: column
  justify-content: center
  padding: 0 10px
  span
    @include flex
    :global(.imageNext)
      margin-right: 10px
  button
    height: 34px
    margin-top: 10px
    width: 100%

@media screen and (max-width: 320px) and (orientation: portrait)
  .btnContainer
    top: -37px
    button
      width: 34px
      min-width: 34px
      height: 30px
      flex: 1

      &:first-of-type
        margin-right: 6px
